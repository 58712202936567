class MainMenu {

    constructor(containerId) {
        const parsePx = value => value.endsWith('px') && Number.parseFloat(value)
        const getPropertyLeft = element => window.getComputedStyle && window.getComputedStyle(element).getPropertyValue('left')
        const setLeft = element => {
            const propertyLeft = getPropertyLeft(element)
            if (propertyLeft) {
                const defaultLeft = parsePx(element.dataset.left || (element.dataset.left = propertyLeft))
                const left = parsePx(propertyLeft)
                const offset = element.getBoundingClientRect().right - (window.innerWidth || document.documentElement.clientWidth)
                element.style.left = `${Math.min(defaultLeft, left - offset)}px`
            }
        }

        document.addEventListener('DOMContentLoaded', () => document.getElementById(containerId).querySelectorAll(`#${containerId} > ul > li`).forEach(element => element.addEventListener('mouseover', () => element.querySelectorAll('.sub-nav').forEach(element => setLeft(element)))))
    }
}

new MainMenu('main-nav')